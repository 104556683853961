let token;
document.addEventListener('DOMContentLoaded',()=>{
    token = window.$('meta[name="csrf-token"]').attr('content');
});


// Prevent parallel requests to tmp experiments from creating as many new projects
let tmp_request = null;
let handle_tmp_request = request => {
    let experiment = $("#experiment");
    if (experiment.length==0 || experiment.data('created_at') != "")
        request.send();
    else {
        if (tmp_request)
            tmp_request.then( ()=>request.send() );
        else{
            tmp_request = request;
            request.then( ()=>tmp_request=null );
            request.send();
        }        
    }
}


let global_then = r=>null;
let add_global_then = f => {
    if (f instanceof Function){
        let tmp_global_then = global_then;
        global_then = r=>{ tmp_global_then(r); f(r); }
    }
}


let isSuccess = s => s == "ok" || (Number(s) >= 200 && Number(s) <= 299)


let request_xml = (o,url,method) => {
    
    let then = ()=>null;
    let success = ()=>null;
    let error = ()=>null;
    let progressCallback = ()=>null;
    
    let handler = {};

    handler.send = ()=>{
        url = url || Uploader.path;
        method = method || "PATCH";

        let request = new XMLHttpRequest();
        request.responseType = "json";

        let form = new FormData();
        // console.log("request xml global params", global_params);
        for (const param in global_params){
            // console.log("Adding param", param, "with value", global_params[param]);
            if (global_params[param] instanceof Array)
                form.append( param , ...global_params[param] );
            else
                form.append( param , global_params[param] );
        }
        for (const param in o){
            if (o[param] instanceof Array)
                form.append( param , ...o[param] );
            else
                form.append( param , o[param] );
        }
        // Track progress
        request.upload.addEventListener("progress", e => {
            // Get the loaded amount and total filesize (bytes)
            let loaded = e.loaded;
            let total = e.total;
            // Calculate percent uploaded
            let percent_complete = loaded / total;
            // console.log("Progress:",percent_complete,e);
            progressCallback(percent_complete);
        });
        // request load handler (transfer complete)
        request.addEventListener("load", e=>{
            // console.log("Upload file response", request);
            if (!isSuccess(request.status)){
                error(request);
                then(request);
                global_then(request);
            }
            else{
                if (request.response.status && !isSuccess(request.response.status))
                    error(request.response);
                else{
                    // refresh_slug(request.response);
                    success(request.response);
                }
                then(request.response);
                global_then(request.response);
            }
        });
        // request error handler
        request.addEventListener("error", e =>  console.log("Request error", e) );
        // request abort handler
        request.addEventListener("abort", e => console.log('Request cancelled', e) );
        // Open and send the request
        request.open(method, url);
        request.setRequestHeader('X-CSRF-Token', token );
        request.send(form);
    }

    handler.then = f=>{
        let oldThen = then;
        then = (...args) => { if (oldThen instanceof Function) oldThen(...args); f(...args); }
        return handler;
    };
    handler.success = f=>{
        let oldSuccess = success;
        success = (...args) => { if (oldSuccess instanceof Function) oldSuccess(...args); f(...args); }
        return handler;
    };
    handler.error = f=>{
        let oldError = error;
        error = (...args) => { if (oldError instanceof Function) oldError(...args); f(...args); }
        return handler;
    };
    handler.progress = c=>{
        let oldProgressCallback = progressCallback;
        progressCallback = (...args) => { if (oldProgressCallback instanceof Function) oldProgressCallback(...args); c(...args); }
        return handler;
    };

    handle_tmp_request( handler );
    return handler;
};


let request_json = (o,url,method) => {

    let then = ()=>null;
    let success = ()=>null;
    let error = ()=>null;

    let handler = {};

    handler.send = ()=> {
        url = url || Uploader.path;
        // console.log("url",url,"path", Uploader.path);
        method = method || "PATCH";
        let request = {
            method: method,
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' , 'X-CSRF-Token': token },
        };
        for (const param in global_params)
            if (!o.hasOwnProperty(param))
                o[param] = global_params[param];
        if (method != "GET")
            request.body = JSON.stringify( o );

        // console.log("Sending request", request);
        fetch(url, request).then( r=>{
            // console.log("r when updating file", r);
            r.json().then( m=>{
                if (!isSuccess(r.status) || (m.status && !isSuccess(m.status)))
                    error(m);
                else{
                    // refresh_slug(m);
                    success(m);
                }
                then(m);
                global_then(m);
            });
        });
    };

    handler.then = f=>{
        let oldThen = then;
        then = (...args) => { if (oldThen instanceof Function) oldThen(...args); f(...args); }
        return handler;
    };
    handler.success = f=>{
        let oldSuccess = success;
        success = (...args) => { if (oldSuccess instanceof Function) oldSuccess(...args); f(...args); }
        return handler;
    };
    handler.error = f=>{
        let oldError = error;
        error = (...args) => { if (oldError instanceof Function) oldError(...args); f(...args); }
        return handler;
    };

    handle_tmp_request( handler );
    return handler;
};


var Uploader = {
    request_xml: request_xml,
    request_json: request_json,
    then: add_global_then
};

let path = "";
Object.defineProperty(Uploader, 'path', {
    get() { 
        if (!path.match(/^(\.(\/|$)|(https?:)?\/)/))
            path = '/'+path;
        if (path != '.' && !path.match(/[/]$/))
            path = path+'/';
        return path;
    },
    set(newpath) {
        if (!newpath.match(/^(\.(\/|$)|(https?:)?\/)/))
            newpath = '/'+newpath;
        if (newpath != '.' && !newpath.match(/[/]$/))
            newpath = newpath+'/';
        path = newpath;
    }
});

let global_params = {};
Object.defineProperty(Uploader, 'global_params', {
    get() { 
        return global_params;
    }
});

export { Uploader };
